import { Pagination } from '@dx-ui/osc-pagination';
import { useCycle } from '@dx-ui/osc-cycle';
import cx from 'classnames';

export type TImageHeadlinerControls = {
  label?: string;
  hideLabel?: boolean;
  hasLoop?: boolean;
  brandComponentTheme?: CmsBrandComponentTheme;
};

const ImageHeadlinerControls = ({
  label = 'Page',
  hideLabel = true,
  hasLoop = true,
  brandComponentTheme,
}: TImageHeadlinerControls) => {
  const { hasSlides, cycleId, active, total, onNextClick, onPreviousClick } = useCycle();
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';
  return hasSlides ? (
    <div
      data-e2e="arrows"
      className={cx(
        'pointer-events-none inset-0 mr-4 justify-end whitespace-nowrap text-xl font-bold lg:text-2xl',
        {
          'text-text-inverse': isDark,
          'brand-ht:text-text-inverse': isLight,
          'image-headliner-controls-default': !isDark && !isLight,
        }
      )}
    >
      <Pagination
        controls={cycleId}
        label={label}
        hideLabel={hideLabel}
        current={active}
        total={total}
        onNextClick={onNextClick}
        onPreviousClick={onPreviousClick}
        loop={hasLoop}
        // TODO: bring this prop back once OSC-1278 is complete
        // // isHeadliner={true}
      />
    </div>
  ) : null;
};

export { ImageHeadlinerControls };
export default ImageHeadlinerControls;
