import * as React from 'react';

import cx from 'classnames';

export function mapTextAlignToClassname(textAlign: CmsAlignContent) {
  return {
    'text-right': textAlign === 'right',
    'text-left': textAlign === 'left',
    'text-center': textAlign === 'center',
  };
}

export type TextualBlockProps = {
  children: React.ReactNode;
  brandComponentTheme?: CmsBrandComponentTheme;
  textAlign: 'left' | 'right' | 'center';
  hasBorderTrim: boolean;
};

export const TextualBlock = React.forwardRef<HTMLDivElement, TextualBlockProps>(
  function TextualBlock(
    { children, brandComponentTheme, textAlign, hasBorderTrim }: TextualBlockProps,
    ref
  ) {
    const isDark = brandComponentTheme === 'dark';
    const isLight = brandComponentTheme === 'light';

    return (
      <div
        className={cx(
          `brand-qq:bg-[url('/modules/assets/images/curio-bg1.png')] relative bg-cover bg-no-repeat`,
          {
            'bg-bg-dark': isDark,
            'bg-bg-light': isLight,
          }
        )}
        data-testid="textBlockBackgroundClassTest"
      >
        <div
          className={cx(`container py-6`, mapTextAlignToClassname(textAlign))}
          data-testid="textBlockContainer"
          ref={ref}
        >
          <div
            className={cx('brand-qq:py-24', {
              'outline outline-primary outline-1 px-10 py-6': hasBorderTrim,
              '!outline-bg brand-ou:!outline-secondary': isDark,
              'brand-qq:outline-bg brand-ht:outline-bg': isLight, //remove brand-qq:outline-bg once background image work is complete
            })}
          >
            {children}
          </div>
        </div>
      </div>
    );
  }
);

export function makeHeadingOverrides({
  brandComponentTheme,
  isAnimated,
  isInView,
  textAlign,
}: {
  brandComponentTheme?: CmsBrandComponentTheme;
  textAlign: 'left' | 'right' | 'center';
  isAnimated: boolean;
  isInView: boolean;
}): Record<'h1' | 'h2' | 'h3' | 'span', { props: { className: string } }> {
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  const headingStyles = cx(
    'brand-qq:text-4xl brand-qq:tracking-wide brand-qq:px-12 brand-qq:pb-4 brand-qq:md:text-6xl brand-qq:md:px-0 brand-wa:uppercase brand-wa:font-sans brand-wa:font-light brand-wa:text-text',
    mapTextAlignToClassname(textAlign),
    {
      'duration-1000 ease-in-out opacity-0 motion-reduce:transition-none motion-reduce:opacity-100':
        isAnimated,
      'translate-y-4': !isInView && isAnimated,
      'opacity-100 translate-y-0': isInView && isAnimated,
      '!text-text-inverse brand-wa:!text-text-inverse': isDark,
      'brand-ht:!text-text-inverse': isLight,
    },
    'font-headline text-primary lg:mb-2'
  );

  return {
    h1: {
      props: {
        className: cx(headingStyles, 'heading-2xl lg:heading-4xl lg:mb-2'),
      },
    },
    h2: {
      props: {
        className: cx(headingStyles, 'heading-xl lg:heading-2xl lg:mb-2'),
      },
    },
    h3: {
      props: {
        className: cx(headingStyles, 'heading-lg lg:heading-xl lg:mb-1'),
      },
    },
    span: {
      props: {
        className: 'text-text py-2 sm:heading-lg lg:heading-xl block',
      },
    },
  };
}

export type TextualDescriptionBlockProps = {
  children: React.ReactNode;
  brandComponentTheme?: CmsBrandComponentTheme;
  textAlign: 'left' | 'right' | 'center';
  isInView: boolean;
  isAnimated: boolean;
};

export function makeDescriptionClassName(
  {
    brandComponentTheme,
    textAlign,
    isInView,
    isAnimated,
  }: Omit<TextualDescriptionBlockProps, 'children'>,
  requiresPadding = true
) {
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  return cx(
    'brand-qq:text-xl brand-qq:text-primary brand-qq:tracking-wider brand-qq:pb-6 brand-qq:px-20 brand-qq:font-serif brand-qq:font-medium brand-qq:md:px-0',
    mapTextAlignToClassname(textAlign),
    {
      'duration-1000 ease-in-out opacity-0 motion-reduce:transition-none motion-reduce:opacity-100':
        isAnimated,
      'translate-y-4': !isInView && isAnimated,
      'opacity-100 translate-y-0': isInView && isAnimated,
      'text-text-inverse brand-qq:text-text-inverse': isDark,
      'brand-ht:text-text-inverse': isLight,
      'py-2': requiresPadding,
      'brand-hp:text-brand': brandComponentTheme !== 'dark',
    },
    'brand-wa:font-serif sm:text-lg lg:text-xl'
  );
}

export function TextualDescriptionBlock({
  children,
  brandComponentTheme,
  textAlign,
  isInView,
  isAnimated,
}: TextualDescriptionBlockProps) {
  const className = makeDescriptionClassName({
    brandComponentTheme,
    textAlign,
    isInView,
    isAnimated,
  });

  return (
    <div data-testid="textBlockBody" className={className}>
      {children}
    </div>
  );
}

export type TextualOrderedListBlockProps = {
  children: React.ReactNode;
  brandComponentTheme?: CmsBrandComponentTheme;
  textAlign: 'left' | 'right' | 'center';
  isInView: boolean;
  isAnimated: boolean;
};

export function makeOrderedListClassName({
  isAnimated,
  isInView,
  brandComponentTheme,
  textAlign,
}: Omit<TextualOrderedListBlockProps, 'children' | 'textAlign'> & {
  textAlign?: TextualOrderedListBlockProps['textAlign'];
}) {
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  return cx(
    'list-outside list-decimal ps-4 text-left',

    textAlign ? mapTextAlignToClassname(textAlign) : null,
    {
      'duration-1000 ease-in-out opacity-0 motion-reduce:transition-none motion-reduce:opacity-100':
        isAnimated,
      'translate-y-4': !isInView && isAnimated,
      'opacity-100 translate-y-0': isInView && isAnimated,
      'text-text-inverse': isDark,
      'brand-ht:text-text-inverse': isLight,
    }
  );
}

export function TextualOrderedListBlock({
  children,
  textAlign,
  isAnimated,
  isInView,
  brandComponentTheme,
}: TextualOrderedListBlockProps) {
  const className = makeOrderedListClassName({
    isAnimated,
    isInView,
    brandComponentTheme,
  });

  return (
    <div
      className={cx(
        'flex items-center',

        textAlign ? mapTextAlignToClassname(textAlign) : null
      )}
    >
      <ul className={className}>{children}</ul>
    </div>
  );
}

export type TextualOrderedListItemBlockProps = {
  children: React.ReactNode;
  brandComponentTheme?: CmsBrandComponentTheme;
};

export function makeOrderedListItemClassName({
  brandComponentTheme,
}: Omit<TextualOrderedListItemBlockProps, 'children'>) {
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  return cx(
    {
      'text-text-inverse': isDark,
      'brand-ht:text-text-inverse': isLight,
    },
    'brand-wa:font-serif sm:text-lg lg:text-xl',
    {
      'brand-hp:text-brand': brandComponentTheme !== 'dark',
    }
  );
}

export function TextualOrderedListItemBlock({
  children,
  brandComponentTheme,
}: TextualOrderedListItemBlockProps) {
  const className = makeOrderedListItemClassName({ brandComponentTheme });

  return (
    <li>
      <div data-testid="textBlockBody" className={className}>
        {children}
      </div>
    </li>
  );
}

export type TextualUnorderedListBlockProps = {
  children: React.ReactNode;
  brandComponentTheme?: CmsBrandComponentTheme;
  textAlign: 'left' | 'right' | 'center';
  isInView: boolean;
  isAnimated: boolean;
};

export function makeUnorderedListClassName({
  isAnimated,
  isInView,
  brandComponentTheme,
  textAlign,
}: Omit<TextualUnorderedListBlockProps, 'children' | 'textAlign'> & {
  textAlign?: TextualUnorderedListBlockProps['textAlign'];
}) {
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  return cx(
    'list-outside list-disc ps-6 text-left',
    textAlign ? mapTextAlignToClassname(textAlign) : null,
    {
      'duration-1000 ease-in-out opacity-0 motion-reduce:transition-none motion-reduce:opacity-100':
        isAnimated,
      'translate-y-4': !isInView && isAnimated,
      'opacity-100 translate-y-0': isInView && isAnimated,
      'text-text-inverse': isDark,
      'brand-ht:text-text-inverse': isLight,
    }
  );
}

export function TextualUnorderedListBlock({
  children,
  textAlign,
  isAnimated,
  isInView,
  brandComponentTheme,
}: TextualUnorderedListBlockProps) {
  const className = makeUnorderedListClassName({
    isAnimated,
    isInView,
    brandComponentTheme,
  });

  return (
    <div
      className={cx(
        'flex items-center',

        textAlign ? mapTextAlignToClassname(textAlign) : null
      )}
    >
      <ul className={className}>{children}</ul>
    </div>
  );
}

export type TextualUnorderedListItemBlockProps = {
  children: React.ReactNode;
  brandComponentTheme?: CmsBrandComponentTheme;
};
export function TextualUnorderedListItemBlock({
  children,
  brandComponentTheme,
}: TextualUnorderedListItemBlockProps) {
  const isDark = brandComponentTheme === 'dark';
  const isLight = brandComponentTheme === 'light';

  return (
    <li>
      <div
        data-testid="textBlockBody"
        className={cx(
          {
            'text-text-inverse': isDark,
            'brand-ht:text-text-inverse': isLight,
          },
          'brand-wa:font-serif py-2 sm:text-lg lg:text-xl',
          {
            'brand-hp:text-brand': brandComponentTheme !== 'dark',
          }
        )}
      >
        {children}
      </div>
    </li>
  );
}

export type TextualBlockLinkProps = React.HTMLAttributes<HTMLAnchorElement> & {
  brandComponentTheme?: CmsBrandComponentTheme;
};

export function makeLinkClassName({ brandComponentTheme }: TextualBlockLinkProps) {
  return cx('underline', {
    'text-text-inverse': brandComponentTheme === 'dark',
    'text-primary hover:text-primary-alt brand-ht:text-text-inverse brand-ht:hover:text-text-inverse':
      brandComponentTheme === 'light',
    'text-primary hover:text-primary-alt ':
      brandComponentTheme !== 'light' && brandComponentTheme !== 'dark',
  });
}

export function TextualBlockLink({ brandComponentTheme, ...props }: TextualBlockLinkProps) {
  const className = makeLinkClassName({ brandComponentTheme });
  return <a {...props} target="_blank" rel="noopener noreferrer" className={className} />;
}
