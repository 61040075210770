/* eslint-disable react/display-name */
import cx from 'classnames';
import type * as React from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'next-i18next';
import type { CaptionProps } from '@dx-ui/osc-caption';
import { Icon } from '@dx-ui/osc-icon';
import type { TFunction } from 'i18next';
import { CarouselImage } from './carousel-image';
import { CarouselNoImage } from './carousel-no-image';
import { useRect } from '@dx-ui/utilities-use-rect';
import { isRtl as isRtlLanguage } from '@dx-ui/utilities-get-language-direction';
import { useRouter } from 'next/router';
import {
  ANALYTICS_GLOBAL_CLICK_EVENT,
  ANALYTICS_NO_VALUE,
  type BaseImageMetrics,
  ImageClickId,
  ImageFunction,
  trackEvent,
} from '@dx-ui/config-metrics';
import { generateCarouselSingleMetrics } from './utils/carousel-analytics';

export type CarouselImages = {
  alt: string;
  url: string;
  captionData?: CaptionProps;
  children?: React.ReactNode;
};

export type CarouselSinglePropsBase = {
  images?: CarouselImages[];
  startingIndex?: number;
  isLoading?: boolean;
  onArrowClick?: (index: number) => void;
  showAlternateControls?: boolean;
  isTailored?: boolean;
  metrics?: Partial<BaseImageMetrics>;
};

export type CarouselSingleProps = CarouselSinglePropsBase & React.HTMLAttributes<HTMLDivElement>;

const CarouselNavigationButton = ({
  children,
  direction,
  isTailored,
  ...rest
}: {
  children: React.ReactNode;
  direction: 'left' | 'right';
  isTailored?: CarouselSinglePropsBase['isTailored'];
} & React.HTMLAttributes<HTMLButtonElement>) => (
  <button
    className="relative flex appearance-none items-center overflow-hidden"
    type="button"
    {...rest}
  >
    {children}
    <span
      className={cx({
        '@[256px]/carousel:px-2 py-2 px-1 bg-bg-inverse opacity-90': !isTailored,
        'bg-[rgb(var(--color-background))] p-2 md:p-3': isTailored,
      })}
    >
      <Icon
        name="arrowhead-left"
        className={cx('@[256px]/carousel:size-7 @[448px]/carousel:size-8 h-3 w-5', {
          '-scale-x-100': direction === 'right',
          'fill-text': isTailored,
          'fill-text-inverse': !isTailored,
        })}
      />
    </span>
  </button>
);

const CarouselControls = ({
  showAlternateControls,
  activeIndex,
  count,
  isRtl = false,
  isTailored = false,
  handlePreviousButtonClick,
  handleNextButtonClick,
  t,
}: {
  isRtl: boolean;
  isTailored?: CarouselSinglePropsBase['isTailored'];
  showAlternateControls?: boolean;
  count: number;
  activeIndex: number;
  handlePreviousButtonClick: () => void;
  handleNextButtonClick: () => void;
  t: TFunction<'osc-carousel'>;
}) => {
  const leftNav = (
    <CarouselNavigationButton
      data-testid="previousCarouselImage"
      direction={isRtl ? 'right' : 'left'}
      onClick={handlePreviousButtonClick}
      isTailored={isTailored}
    >
      <span className="sr-only">{t('previousImage')}</span>
    </CarouselNavigationButton>
  );

  const rightNav = (
    <CarouselNavigationButton
      data-testid="nextCarouselImage"
      direction={isRtl ? 'left' : 'right'}
      onClick={handleNextButtonClick}
      isTailored={isTailored}
    >
      <span className="sr-only">{t('nextImage')}</span>
    </CarouselNavigationButton>
  );

  const paginationCounter = showAlternateControls ? (
    <>
      <p
        aria-hidden
        className={cx('@[256px]/carousel:text-base select-none overflow-hidden text-xs', {
          'bg-[rgb(var(--color-background))] p-2.5 md:p-4 text-xs tracking-wide font-semibold':
            isTailored,
          'text-text-inverse': !isTailored,
        })}
      >
        {activeIndex + 1} / {count}
      </p>
      <span className="sr-only" aria-live="polite">
        {t('imageCount', { activeIndex: activeIndex + 1, imageCount: count })}
      </span>
    </>
  ) : (
    <>
      <p
        data-osc-product="carousel-label"
        aria-hidden
        className="bg-bg-inverse text-text-inverse @[256px]/carousel:text-sm @[448px]/carousel:text-base absolute bottom-0 right-0 flex select-none items-center overflow-hidden px-2 py-1 text-xs opacity-90 rtl:left-0 rtl:right-auto"
      >
        {activeIndex + 1} / {count}
      </p>
      <span className="sr-only" aria-live="polite">
        {t('imageCount', { activeIndex: activeIndex + 1, imageCount: count })}
      </span>
    </>
  );

  return showAlternateControls ? (
    <div
      data-testid="testAlternateControlClassName"
      className={cx('absolute flex', {
        'bottom-0 right-10 opacity-90 bg-bg-inverse items-center': !isTailored,
        'bottom-1 right-1 md:bottom-4 md:right-4 gap-0.5 justify-evenly items-stretch': isTailored,
      })}
    >
      {isTailored ? (
        <>
          {leftNav}
          {rightNav}
          {paginationCounter}
        </>
      ) : (
        <>
          {leftNav}
          {paginationCounter}
          {rightNav}
        </>
      )}
    </div>
  ) : (
    <>
      {paginationCounter}
      <div className="absolute inset-y-1/2 flex w-full items-center justify-between">
        {leftNav}
        {rightNav}
      </div>
    </>
  );
};

/**
 *
 * A carousel that displays a single image at a time. Includes a built in loading (shimmer) and error handling state
 * @returns JSX.element
 */
export const CarouselSingle = ({
  className,
  images,
  startingIndex = 0,
  onArrowClick,
  isLoading = false,
  showAlternateControls,
  isTailored = false,
  metrics,
  ...rest
}: CarouselSingleProps) => {
  const { t } = useTranslation('osc-carousel');
  const { locale = 'en' } = useRouter();
  const isRtl = isRtlLanguage(locale);
  const [activeIndex, setActiveIndex] = useState(startingIndex);
  const [touchStart, setTouchStart] = useState(0);
  const [touchEnd, setTouchEnd] = useState(0);
  const ref = useRef<HTMLDivElement | null>(null);

  const count = images?.length ?? 0;

  const rect = useRect({ ref: count > 0 ? ref : { current: null } });

  const width = rect?.width ?? 0;
  const height = rect?.height ?? 0;
  const imageTranslateX = `${!isRtl ? '-' : ''}${
    activeIndex === 0 ? 0 : 100 / (count / activeIndex)
  }%`;

  const handleNextButtonClick = () => {
    const newIndex = images && activeIndex === images.length - 1 ? 0 : activeIndex + 1;
    if (onArrowClick) onArrowClick(newIndex);
    setActiveIndex(newIndex);

    trackEvent(
      ANALYTICS_GLOBAL_CLICK_EVENT,
      generateCarouselSingleMetrics({
        metrics,
        images,
        clickId: ImageClickId.Scroll,
        itemTitle: images?.[activeIndex]?.captionData?.caption || ANALYTICS_NO_VALUE,
        position: activeIndex + 1, // 1-based index
      })
    );
  };
  const handlePreviousButtonClick = () => {
    const newIndex = images && activeIndex === 0 ? images.length - 1 : activeIndex - 1;
    if (onArrowClick) onArrowClick(newIndex);
    setActiveIndex(newIndex);

    trackEvent(
      ANALYTICS_GLOBAL_CLICK_EVENT,
      generateCarouselSingleMetrics({
        metrics,
        images,
        clickId: ImageClickId.Scroll,
        itemTitle: images?.[activeIndex]?.captionData?.caption || ANALYTICS_NO_VALUE,
        position: activeIndex + 1, // 1-based index
      })
    );
  };

  const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
    e.stopPropagation();
    setTouchStart(e.targetTouches[0]?.clientX ?? 0);
  };
  const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
    setTouchEnd(e.targetTouches[0]?.clientX ?? 0);
  };
  const handleTouchEnd = () => {
    //swipe sensitivity can be adjusted - travel distance in [number] pixels of user swipe event
    const SWIPE_SENSITIVITY = 50;
    //swipe left
    if (touchStart - touchEnd > SWIPE_SENSITIVITY && touchEnd > 0) handleNextButtonClick();
    //swipe right
    if (touchStart - touchEnd < -SWIPE_SENSITIVITY && touchEnd > 0) handlePreviousButtonClick();

    setTouchStart(0);
    setTouchEnd(0);
  };

  /** If content of gallery is loading present shimmer state to prevent CLS */
  if (isLoading)
    return (
      <div
        className={cx(
          'bg-bg-alt relative aspect-[3/2] w-full animate-pulse overflow-hidden',
          className
        )}
        data-testid="singleImageCarouselLoading"
        ref={ref}
        {...rest}
      />
    );

  /** No images are available return placeholder with message */
  if (!images || !count) {
    return <CarouselNoImage className={className} />;
  }

  return (
    <div
      className={cx('@container/carousel relative aspect-[3/2] w-full overflow-hidden', className)}
      onTouchEnd={handleTouchEnd}
      onTouchMove={handleTouchMove}
      onTouchStart={handleTouchStart}
      ref={ref}
      {...rest}
    >
      <div
        className="ease-out-quint relative flex select-none overflow-hidden transition duration-500"
        data-testid="singleImageCarousel"
        style={{
          height,
          width: `${count * 100}%`,
          transform: `translate3d(${imageTranslateX}, 0, 0)`,
        }}
      >
        {images?.map((imageProps, index) => (
          <div
            className="bg-bg-alt relative size-full overflow-hidden"
            key={`carousel-image-${imageProps.url}`}
            style={{ width }}
            data-testid={`image-carousel-wrap-${index + 1}`}
          >
            <CarouselImage
              isActive={index === activeIndex}
              {...imageProps}
              imageProps={{ priority: index === 0 }}
              metrics={generateCarouselSingleMetrics({
                metrics,
                position: index + 1, // 1-based index
                images,
                itemTitle: imageProps?.captionData?.caption || ANALYTICS_NO_VALUE,
                clickId: ImageClickId.Img,
                imageFunction: ImageFunction.Link,
              })}
            />
            {imageProps.children ? <div>{imageProps.children}</div> : null}
          </div>
        ))}
      </div>
      {/* Only show carousel controls/count if we have more than 1 image passed in */}
      {count > 1 ? (
        <CarouselControls
          handleNextButtonClick={handleNextButtonClick}
          handlePreviousButtonClick={handlePreviousButtonClick}
          count={count}
          t={t}
          activeIndex={activeIndex}
          showAlternateControls={showAlternateControls}
          isTailored={isTailored}
          isRtl={isRtl}
        />
      ) : null}
    </div>
  );
};

export default CarouselSingle;
