import type * as React from 'react';
import { useId } from 'react';
import cx from 'classnames';

import { CustomMarkdown } from '@dx-ui/osc-custom-markdown';

export type BrandTextBodyType = {
  className?: string;
  baseAppUrl?: string;
  lang?: string;
  testId?: string | null;
  isStaticContentPage?: boolean;
  children: React.ReactNode;
  brandComponentTheme?: CmsBrandComponentTheme;
};

/**
 * Can utilize `<CustomMarkdown>` or `children` to add the body text content.
 * There is not a character limit on the text block body but individual components may have recommended character lengths, see components for more details.
 */
export const BrandTextBody = ({
  children,
  className = '',
  testId = 'brandTextBody',
  isStaticContentPage, // to enable segregation of styles, logic of textblock components for static pages
  brandComponentTheme,
}: BrandTextBodyType) => {
  const id = useId();

  return typeof children === 'string' ? (
    /* Adding a unique id to children props passed in CustomMarkdown mainly headings */
    <div
      data-testid={testId}
      className={cx(className, 'brand-wa:font-serif py-2 sm:text-lg lg:text-xl', {
        'brand-hp:text-brand': brandComponentTheme !== 'dark',
        'brand-ht:text-text-inverse': brandComponentTheme === 'light',
      })}
    >
      <CustomMarkdown
        id={`textblock-body-section-${id}`}
        isStaticContentPage={isStaticContentPage}
        brandComponentTheme={brandComponentTheme}
      >
        {children}
      </CustomMarkdown>
    </div>
  ) : (
    children
  );
};
