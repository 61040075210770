// Note: See old file history at https://gitlab.svc-m.hhc.hilton.com/pac/hiw/dx-hotels-ui/-/blob/17b699b0671894da33c7751415283f954ef01838/src/components/custom-markdown/index.tsx
import Markdown from 'markdown-to-jsx';
import cx from 'classnames';
import merge from 'lodash/merge';

import { isBrowser } from '@dx-ui/utilities-is-browser';

import type { MarkdownToJSX } from 'markdown-to-jsx';
import { Children, type ReactNode } from 'react';

export type TagRendererProps = {
  tag: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | 'table' | 'th' | 'td' | 'b' | 'p';
  children: ReactNode;
};

const targetOpensInNewWindow = (href: string) => (href ? href.endsWith('target_blank') : false);

const TagRenderer = ({ tag: Tag, children, ...props }: TagRendererProps) => (
  <Tag {...props}>{children}</Tag>
);

export type LinkRendererProps = {
  href: string;
  children: ReactNode;
  brandComponentTheme?: CmsBrandComponentTheme;
};

const MarkdownLinkRenderer = (props: LinkRendererProps) => {
  let { href } = props;
  const { children } = props;

  if (!href) {
    return null;
  }

  if (Children.count(props.children) === 0) {
    return null;
  }

  if (targetOpensInNewWindow(href)) {
    href = href.replace('/target_blank', '');
    return (
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        className={cx('underline', {
          'text-text-inverse': props.brandComponentTheme === 'dark',
          'text-primary hover:text-primary-alt brand-ht:text-text-inverse brand-ht:hover:text-text-inverse':
            props.brandComponentTheme === 'light',
          'text-primary hover:text-primary-alt ':
            props.brandComponentTheme !== 'light' && props.brandComponentTheme !== 'dark',
        })}
      >
        {children}
      </a>
    );
  }
  if (href.endsWith('_backToTop')) {
    href = href.replace('_backToTop', '');
    return (
      <>
        <a
          href={href}
          className={cx('underline', {
            'text-text-inverse': props.brandComponentTheme === 'dark',
            'text-primary hover:text-primary-alt brand-ht:text-text-inverse brand-ht:hover:text-text-inverse':
              props.brandComponentTheme === 'light',
            'text-primary hover:text-primary-alt ':
              props.brandComponentTheme !== 'light' && props.brandComponentTheme !== 'dark',
          })}
        >
          {children}
        </a>
        <div />
      </>
    );
  }
  if (href.endsWith('Join_US')) {
    href = href.replace('Join_US', '');
    return (
      <a
        href={href}
        className={cx('join-us-link underline', {
          'text-text-inverse': props.brandComponentTheme === 'dark',
          'text-primary hover:text-primary-alt brand-ht:text-text-inverse brand-ht:hover:text-text-inverse':
            props.brandComponentTheme === 'light',
          'text-primary hover:text-primary-alt ':
            props.brandComponentTheme !== 'light' && props.brandComponentTheme !== 'dark',
        })}
      >
        {children}
      </a>
    );
  }

  if (href === 'teconsent') {
    //`trustarc` is for analytics tracking (cookie preferences page) from GDPR countries
    if (isBrowser && window?.location?.href?.includes('?trustarc')) {
      return (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          id={href}
          className={cx('join-us-link underline', {
            'text-text-inverse': props.brandComponentTheme === 'dark',
            'text-primary hover:text-primary-alt brand-ht:text-text-inverse brand-ht:hover:text-text-inverse':
              props.brandComponentTheme === 'light',
            'text-primary hover:text-primary-alt ':
              props.brandComponentTheme !== 'light' && props.brandComponentTheme !== 'dark',
          })}
        >
          {}
        </a>
      );
    }
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a
        id={href}
        className={cx('join-us-link underline', {
          'text-text-inverse': props.brandComponentTheme === 'dark',
          'text-primary hover:text-primary-alt brand-ht:text-text-inverse brand-ht:hover:text-text-inverse':
            props.brandComponentTheme === 'light',
          'text-primary hover:text-primary-alt ':
            props.brandComponentTheme !== 'light' && props.brandComponentTheme !== 'dark',
        })}
      >
        {children}
      </a>
    );
  }

  if (href.startsWith('#') && Array.isArray(children) && children.length === 0) {
    return <span id={href.replace('#', '')} />;
  }
  return (
    <a
      href={href}
      className={cx('join-us-link break-words underline', {
        'text-text-inverse': props.brandComponentTheme === 'dark',
        'text-primary hover:text-primary-alt brand-ht:text-text-inverse brand-ht:hover:text-text-inverse':
          props.brandComponentTheme === 'light',
        'text-primary hover:text-primary-alt ':
          props.brandComponentTheme !== 'light' && props.brandComponentTheme !== 'dark',
      })}
    >
      {children}
    </a>
  );
};

export type CustomMarkdownProps = {
  children: string;
  options?: MarkdownToJSX.Options;
  isStaticContentPage?: boolean;
  id?: string;
  brandComponentTheme?: CmsBrandComponentTheme;
};

/**
 * CustomMarkdown renders a markdown string to the DOM using React components.
 * It wraps the NPM package `markdown-to-jsx`, and provides renderers for many common markdown elements with specific styling classes
 */
export const CustomMarkdown = ({
  isStaticContentPage,
  children,
  options,
  id,
  brandComponentTheme,
}: CustomMarkdownProps) => {
  const baseClass = cx('font-headline', {
    'text-text-inverse': brandComponentTheme === 'dark',
    'brand-ht:text-text-inverse': brandComponentTheme === 'light',
  });

  const defaultOptions = {
    overrides: {
      a: {
        component: MarkdownLinkRenderer,
        props: {
          brandComponentTheme,
        },
      },
      h1: {
        component: TagRenderer,
        props: {
          tag: 'h1',
          tabIndex: '-1',
          className: cx(baseClass, 'heading-2xl lg:heading-4xl focus:ring lg:mb-2'),
          ...(id ? { id } : {}),
        },
      },
      h2: {
        component: TagRenderer,
        props: {
          tag: 'h2',
          ...(id ? { id } : {}),
          className: cx(
            baseClass,
            'heading-xl lg:heading-2xl',
            isStaticContentPage ? 'lg:mb-2' : 'lg:mb-1'
          ), // segregating styles for static pages in Brand & Brand pages,
        },
      },
      h3: {
        component: TagRenderer,
        props: {
          tag: 'h3',
          ...(id ? { id } : {}),
          className: cx(baseClass, 'heading-lg lg:heading-xl lg:mb-1'),
        },
      },
      table: {
        component: TagRenderer,
        props: {
          tag: 'table',
          className: 'table-auto -ml-2',
        },
      },
      thead: {
        component: TagRenderer,
        props: {
          tag: 'thead',
          className: 'border border-border-alt sm:overflow-hidden',
        },
      },
      th: {
        component: TagRenderer,
        props: {
          tag: 'th',
          className: 'bg-border-alt pt-3 pb-3 px-2',
        },
      },
      td: {
        component: TagRenderer,
        props: {
          tag: 'td',
          className: 'px-2 py-1 h-4',
        },
      },
      b: { component: TagRenderer, props: { tag: 'b' } },
      p: { component: TagRenderer, props: { tag: 'p' } },
    },
  };

  const mergedOpts = merge(defaultOptions, options);
  let markdownArray: string[];
  let updatedChildren: string;

  //checking if the block of markdown is a table
  if (children[0] === '|' && children.slice(-1) === '|') {
    updatedChildren = children;
  }

  //if the block of markdown is not a table, then making the line breaks of a markdown block as '<div class="p-2"></div>'
  else {
    markdownArray = children.split(/\r\n|\n/);
    updatedChildren = markdownArray
      .map((markdown, index) => {
        if (markdown === '') {
          if (markdownArray[index + 1] === undefined || markdownArray[index + 1] === null)
            return '';
          return '<div class="p-2"></div>\n';
        }
        return `${markdown} \n\n`;
      })
      .join('');
  }

  return <Markdown options={mergedOpts}>{updatedChildren}</Markdown>;
};
