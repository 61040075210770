import type { Link } from '@dx-ui/osc-link';
import type { AspectRatio, TResponsiveImage } from '@dx-ui/osc-responsive-image';

export type TFullWidthImage = {
  id?: string;
  /**
   * Aspect Ratio of the Image at 1280px +
   */
  desktopImageUrl: string;
  /**
   * URL of the Image at 640px - 1280px
   */
  tabletImageUrl: string;
  /**
   * URL of the Image at 0px - 640px
   */
  mobileImageUrl: string;
  captionData?: TResponsiveImage['captionData'];
  /**
   * Alternative text of the Image (a11y)
   */
  imageAltText: string;
  /**
   * Text of the button that opens a Dialog box
   */
  imageOverlayButtonText: string;
  /**
   * Title at the top of the Dialog box
   */
  title: string;
  /**
   * Description(paragraph) at the bottom of the Dialog box
   */
  description: JSX.Element | string;
  /**
   * Link at the bottom of the Dialog box
   */
  link: Link & { experimentationConfiguration?: CmsExperimentationConfiguration };
  /**
   * Function that gets executed when the Dialog box is being open
   */
  overlayButtonClicked?: () => void;
};

export const FullWidthImageAspectRatio: AspectRatio = '384:113';
export const FullWidthImageDialogAspectRatio: AspectRatio = '16:9';
