import {
  ANALYTICS_NO_VALUE,
  type BaseImageMetrics,
  ImageClickId,
  type ImageClickIdType,
  ImageFunction,
  ImageType,
} from '@dx-ui/config-metrics';
import type { CarouselSingleMetrics } from '../types/carousel-analytics';

type GenerateCarouselSingleMetricsParams = {
  metrics?: Partial<BaseImageMetrics>;
  position?: number;
  images?: { alt: string }[];
  itemTitle?: string;
  clickId: ImageClickIdType;
  imageFunction?: (typeof ImageFunction)['Link'];
};

/**
 * Utility function to generate carousel metrics
 */
export function generateCarouselSingleMetrics({
  metrics,
  position,
  images,
  itemTitle = ANALYTICS_NO_VALUE,
  clickId = ImageClickId.Scroll,
  imageFunction,
}: GenerateCarouselSingleMetricsParams): CarouselSingleMetrics {
  const carouselMetrics = {
    ...metrics,
    click: {
      ...metrics?.click,
      clickId,
    },
    image: {
      ...metrics?.image,
      headline: metrics?.image?.headline || ANALYTICS_NO_VALUE,
      count: images?.length || 0,
      position: position || 0,
      itemTitle: itemTitle || ANALYTICS_NO_VALUE,
      type: ImageType.Carousel,
    },
  };

  if (imageFunction === ImageFunction.Link) {
    carouselMetrics.image = {
      ...carouselMetrics.image,
      function: ImageFunction.Link,
    };
  }

  return carouselMetrics;
}

/**
 * Utility function to generate CPM carousel metrics
 * The returned object will serve as a partial image metric object to be extended within the CarouselSingle component
 */
export function generateCpmCarouselMetrics(
  headline: string | null | undefined
): Partial<BaseImageMetrics> {
  return {
    click: {
      clickId: ImageClickId.Img,
    },
    image: {
      // All the other CarouselSingleMetrics attributes will be set in CarouselSingle
      headline: headline || ANALYTICS_NO_VALUE,
      type: ImageType.Carousel,
    },
  };
}
