import { useRef, useEffect } from 'react';
import { useTranslation } from 'next-i18next';
import cx from 'classnames';
import { Dialog } from '@dx-ui/osc-dialog-v2';
import { ResponsiveImage } from '@dx-ui/osc-responsive-image';
import { BrandTextBody } from '@dx-ui/osc-brand-text-body';
import { BrandTextHeader } from '@dx-ui/osc-brand-text-header';
import type { SecurityDialogWithContentType } from './security-dialog.types';
import { useRect } from '@dx-ui/utilities-use-rect';

/**
 * Security Dialog, or Gating Modal, is currently used for Corporate Advantage and travel agent pages.
 * This is a modal/dialog without a close button.
 *
 */
export const SecurityDialog = (props: SecurityDialogWithContentType) => {
  const [t] = useTranslation('osc-marketing-dx-dialog-with-content');
  const ref = useRef<React.ElementRef<'div'>>(null);
  const rect = useRect({ ref });
  const modalCloseButtonRef = useRef<HTMLButtonElement>(null);

  const {
    imageAspectRatio = '3:2',
    imageUrl,
    imageUrls,
    imageAltText,
    isOpen,
    id,
    headline,
    content,
    link,
    onClickLink,
    title,
    ...dialogProps
  } = props;

  const ariaLabelLink = [link?.adaDescription || '', link?.isNewWindow ? t('newWindow') : '']
    .filter(Boolean)
    .join(' - ');

  useEffect(() => {
    if (isOpen) {
      modalCloseButtonRef.current && modalCloseButtonRef.current.focus();
    }
  }, [isOpen, id]);

  const isHeadlineShown = headline && headline !== props?.title;

  let body: JSX.Element | null = null;
  if (content) {
    if (typeof content === 'string') {
      body = <BrandTextBody {...props}>{content}</BrandTextBody>;
    } else {
      body = <div className="prose lg:prose-xl my-2">{content}</div>;
    }
  }

  return (
    <Dialog isOpen={isOpen} title={title} size="sm" {...dialogProps}>
      <div
        data-testid="activeDialogWithContentBox"
        className="flex h-full flex-col sm:h-auto"
        ref={ref}
      >
        {/* This is needed in case we only need 1 img src that can be used for tablet, desktop and mobile screens */}
        {imageUrl ? (
          <div className="flex items-center justify-items-center overflow-y-hidden">
            <ResponsiveImage
              id={id}
              aspectRatio={imageAspectRatio}
              imageUrl={imageUrl}
              altText={imageAltText ?? ''}
              width={rect?.width ?? 0}
            />
          </div>
        ) : null}
        {/* This is needed in case we have separate Urls for tablet, desktop and mobile screens */}
        {Array.isArray(imageUrls) && imageUrls.length > 0
          ? imageUrls.map((imgUrl) => {
              return (
                <div
                  className={cx(
                    'flex items-center justify-items-center overflow-y-hidden',
                    imgUrl?.className
                  )}
                  key={imgUrl.id}
                  data-testid={`dialog-with-content-img-wrapper-${imgUrl?.screen}`}
                >
                  <ResponsiveImage
                    id={id}
                    aspectRatio={imageAspectRatio}
                    imageUrl={imgUrl?.url}
                    altText={imageAltText ?? ''}
                    width={rect?.width ?? 0}
                  />
                </div>
              );
            })
          : null}

        <div className="box-border sm:px-8" aria-live="polite">
          {isHeadlineShown ? <BrandTextHeader {...props}>{headline}</BrandTextHeader> : null}

          {body}

          {link?.label && link?.url ? (
            <div className="inline-block w-full pb-4 pt-8 text-center">
              <a
                className="btn btn-primary btn-xl"
                href={link?.url}
                target={link?.isNewWindow ? '_blank' : '_self'}
                onClick={onClickLink}
                aria-label={ariaLabelLink}
                data-testid="dialogWithContentCTA"
                rel="noreferrer"
              >
                {link.label}
              </a>
            </div>
          ) : null}
        </div>
      </div>
    </Dialog>
  );
};
